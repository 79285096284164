import {firestore} from 'firebase';

export interface FailedImport {
    id: string;
    name: string;
    size: number;
    uploadedAt: firestore.Timestamp;
    importError: string;
}

// "any"-type is appropriate for typeguards
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isFailedImport(object: any): object is FailedImport {
    return 'id' in object && 'name' in object && 'size' in object && 'uploadedAt' in object && 'importError' in object;
}
