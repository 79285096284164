import Button, {ButtonProps} from '@material-ui/core/Button';
import React, {useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/styles';
import {reportError} from '../core/errorReportingClient';

const useStyles = makeStyles(() => ({
    buttonWrapper: {position: 'relative'},
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12
    }
}));

interface Props extends ButtonProps {
    action: () => void;
}

export function ButtonWithProgress(props: Props) {
    const classes = useStyles();
    const {action, ...rest} = props;

    const [isLoading, setLoading] = useState(false);

    const onClick = async () => {
        setLoading(true);
        try {
            await action();
        } catch (error) {
            await reportError({message: error.message});
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.buttonWrapper}>
            <Button {...rest} disabled={isLoading} onClick={onClick} style={{width: '100%'}} />
            {isLoading && <CircularProgress size={24} className={classes.buttonProgress} aria-label={'is-loading'} />}
        </div>
    );
}
