import {Query, QuerySnapshot} from './typeAliases';
import {useEffect, useState} from 'react';
import {fbCaduceus} from './firebase';

export interface ListenResult {
    isLoading: boolean;
    querySnapshot: QuerySnapshot | null;
}

const initialState = {isLoading: true, querySnapshot: null};

export function useQueryListener(query: Query) {
    const [state, setState] = useState<ListenResult>(initialState);
    const [previousQuery, setQuery] = useState<Query>(query);

    if (previousQuery !== query) {
        setQuery(query);
        setState(initialState);
    }

    useEffect(() => {
        return query.onSnapshot(it => {
            setState({isLoading: false, querySnapshot: it});
        });
    }, [query]);

    return state;
}

export function useFirestoreCollection(collectionName: string) {
    const [state, setState] = useState<ListenResult>(initialState);
    const [previousCollection, setCollection] = useState(collectionName);

    if (previousCollection !== collectionName) {
        setCollection(collectionName);
        setState(initialState);
    }

    useEffect(() => {
        return fbCaduceus
            .firestore()
            .collection(collectionName)
            .onSnapshot(it => {
                setState({isLoading: false, querySnapshot: it});
            });
    }, [collectionName]);

    return state;
}
