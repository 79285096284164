import {makeStyles} from '@material-ui/styles';

export const container = {
    padding: 10,
    marginTop: '10px'
};

export const useStyles = makeStyles(() => ({
    myPaper: {
        maxWidth: '100%',
        padding: 20
    }
}));
