import IconButton, {IconButtonProps} from '@material-ui/core/IconButton';
import React, {useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/styles';
import {reportError} from '../core/errorReportingClient';

const useStyles = makeStyles(() => ({
    iconButtonWrapper: {position: 'relative', width: 'fit-content'},
    iconButtonProgress: {
        position: 'absolute',
        top: -1,
        left: -1,
        zIndex: 1
    }
}));

interface Props extends IconButtonProps {
    action: () => void;
}

export function IconButtonWithProgress(props: Props) {
    const classes = useStyles();
    const {action, ...rest} = props;

    const [isLoading, setLoading] = useState(false);

    const onClick = async () => {
        setLoading(true);
        try {
            await action();
        } catch (error) {
            await reportError({message: error.message});
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={classes.iconButtonWrapper}>
            <IconButton {...rest} disabled={isLoading} onClick={onClick} />
            {isLoading && (
                <CircularProgress size={26} className={classes.iconButtonProgress} aria-label={'is-loading'} />
            )}
        </div>
    );
}
