import Paper from '@material-ui/core/Paper';
import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/styles';
import {chain} from 'lodash';
import {useQueryListener} from '../../../../core/hooks';
import {updatesForFieldQuery} from '../../queries';

const maxDocs = 500;

const useStyles = makeStyles(() => ({
    root: {width: '100%', height: '100%', padding: '10px'},
    heading: {fontWeight: 'bold', margin: '10px 0'},
    warning: {
        backgroundColor: '#ffe5e5',
        border: '1px solid #ff6464',
        padding: '3px',
        borderRadius: '3px',
        color: 'red',
        fontSize: '13px',
        marginBottom: '10px'
    },
    clickable: {cursor: 'pointer', '&:hover': {background: '#eee'}}
}));

export function UpdatesForFieldTile(props: {importId: string; field: string}) {
    const classes = useStyles();

    const query = useMemo(() => {
        return updatesForFieldQuery(props.importId, props.field, maxDocs);
    }, [props.importId, props.field]);

    const queryResult = useQueryListener(query);

    if (queryResult.isLoading) {
        return <Paper className={classes.root} aria-label={`updates-for-field-loading`} />;
    }

    const results = queryResult.querySnapshot!!.docs;
    const updateCountByFieldName = chain(results)
        .map(it => it.data().updates)
        .countBy(it => it[`${props.field}`])
        .value();

    const updatesByNewValue = Object.keys(updateCountByFieldName).map(fieldName => {
        return (
            <div className={classes.clickable} key={fieldName} style={{display: 'flex'}}>
                <span style={{flex: 1}}>{fieldName}</span>
                <span>{updateCountByFieldName[fieldName]}</span>
            </div>
        );
    });

    return (
        <Paper className={classes.root} aria-label={`updates-for-field`}>
            <div className={classes.heading}>Updates f&uuml;r {props.field}</div>
            {results.length === maxDocs ? (
                <div className={classes.warning}>
                    Auswahl begrenzt auf {maxDocs} Ergebnisse. Summen k&ouml;nnen abweichen.
                </div>
            ) : null}
            {updatesByNewValue}
        </Paper>
    );
}
