import Paper from '@material-ui/core/Paper';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {frontendClient} from '../../../../core/frontendClient';
import {functionsClient} from '../../../../core/functionsClient';
import Delete from '@material-ui/icons/Delete';
import Done from '@material-ui/icons/Done';
import {ButtonWithProgress} from '../../../../components/ButtonWithProgress';
import {IconButtonWithProgress} from '../../../../components/IconButtonWithProgress';
import {useAuth} from '../../../../auth/AuthProvider';

const useStyles = makeStyles(() => ({
    root: {width: '100%', height: '100%', padding: '10px'},
    heading: {fontWeight: 'bold', margin: '10px 0'},
    clickable: {cursor: 'pointer', '&:hover': {background: '#eee'}}
}));

export function UpdatesOverviewTile(props: {
    importId: string;
    affectedSkus: number;
    updateCounts: {[key: string]: number};
    selectField: (key: string) => () => void;
}) {
    const classes = useStyles();
    const auth = useAuth().firebaseAuth!!;
    const importId = props.importId;

    const updatesByFieldname = Object.keys(props.updateCounts).map(fieldName => {
        const params = {importId, fieldName, auth};
        const deleteUpdatesForField = () => frontendClient.deleteUpdatesForField(params);
        const applyUpdatesForField = () => frontendClient.applyUpdatesForField(params);

        return (
            <div
                className={classes.clickable}
                key={fieldName}
                style={{display: 'flex', alignItems: 'center', height: '28px'}}
                onClick={props.selectField(fieldName)}>
                <span style={{flex: 1}}>{fieldName}</span>
                <span>{props.updateCounts[fieldName]}</span>
                <span style={{marginLeft: '5px', display: 'flex'}}>
                    <IconButtonWithProgress style={{padding: 0}} action={applyUpdatesForField}>
                        <Done />
                    </IconButtonWithProgress>
                    <IconButtonWithProgress style={{padding: 0}} action={deleteUpdatesForField}>
                        <Delete />
                    </IconButtonWithProgress>
                </span>
            </div>
        );
    });

    return (
        <Paper className={classes.root}>
            <div className={classes.heading}>Updates pro Feld</div>
            {updatesByFieldname}
            <div className={classes.heading}>Insgesamt sind {props.affectedSkus} SKUs betroffen.</div>
            <ButtonWithProgress
                aria-label={`Updates Anwenden`}
                variant={'contained'}
                color={'primary'}
                action={() => functionsClient.applyUpdates(importId)}>
                Alle Anwenden
            </ButtonWithProgress>
            <ButtonWithProgress
                aria-label={'Updates Verwerfen'}
                variant={'outlined'}
                color={'secondary'}
                action={() => frontendClient.deleteAllUpdates(importId, auth)}>
                Alle Verwerfen
            </ButtonWithProgress>
        </Paper>
    );
}
