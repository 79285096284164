import React from 'react';
import {ImportSuccess} from '../../../core/model/ImportSuccess';
import {makeStyles} from '@material-ui/styles';
import {NewOverviewTile} from './NewOverviewTile';
import {UpdatesForGroupTile} from './UpdatesForGroupTile';
import {NewUpdatesForFieldTile} from './NewUpdatesForFieldTile';
import {UnconfirmedUpdate} from './model/UnconfirmedUpdate';
import {useUpdatePanelReducer} from './updatePanelReducer';
import {useFirestoreCollection} from '../../../core/hooks';

const useStyles = makeStyles(() => ({
    container: {display: 'flex', flexDirection: 'row', margin: '5px 0px', minHeight: '175px'},
    panel: {margin: '5px', padding: '10px'},
    smallPanel: {display: 'flex', flexDirection: 'column', flex: '0 1 300px', margin: '5px'}
}));

export const NewUpdatesPanel = (props: {pendingFile: ImportSuccess}) => {
    const classes = useStyles();
    const {state, selectGroup, selectField} = useUpdatePanelReducer();

    const productUpdates = useUpdates(props.pendingFile.id, 'product');
    const articleUpdates = useUpdates(props.pendingFile.id, 'article');
    const variationUpdates = useUpdates(props.pendingFile.id, 'variation');

    function getSelectedUpdates(selectedGroup: 'product' | 'article' | 'variation') {
        switch (selectedGroup) {
            case 'product':
                return productUpdates;
            case 'article':
                return articleUpdates;
            case 'variation':
                return variationUpdates;
        }
    }

    return (
        <div className={classes.container} role={'tabpanel'} aria-label={`${props.pendingFile.name}-updates-tab`}>
            <div className={classes.smallPanel}>
                <NewOverviewTile
                    importId={props.pendingFile.id}
                    productUpdates={productUpdates}
                    articleUpdates={articleUpdates}
                    variationUpdates={variationUpdates}
                    selectGroup={selectGroup}
                />
            </div>
            {state.selectedGroup !== null && (
                <>
                    <div className={classes.smallPanel}>
                        <UpdatesForGroupTile
                            importId={props.pendingFile.id}
                            updates={getSelectedUpdates(state.selectedGroup)}
                            selectField={selectField}
                            type={state.selectedGroup}
                        />
                    </div>
                    <div className={classes.smallPanel}>
                        {state.selectedField && (
                            <NewUpdatesForFieldTile
                                updates={getSelectedUpdates(state.selectedGroup)}
                                field={state.selectedField}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

function useUpdates(importId: string, group: 'product' | 'article' | 'variation'): UnconfirmedUpdate[] {
    const result = useFirestoreCollection(`product_data_incoming_files/${importId}/${group}_updates`);
    if (result.querySnapshot === null) return [];
    return result.querySnapshot.docs.map(it => it.data() as UnconfirmedUpdate);
}
