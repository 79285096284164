import {fbCaduceus} from '../core/firebase';

async function register(params: {email: string; password: string}) {
    const userCredential = await fbCaduceus.auth().createUserWithEmailAndPassword(params.email, params.password);

    if (userCredential.user !== null) {
        await userCredential.user.sendEmailVerification({
            url: process.env.REACT_APP_DOMAIN!!
        });
    }
}

export {register};
