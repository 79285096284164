import React, {ReactElement} from 'react';
import {Redirect, Route, RouteComponentProps, RouteProps} from 'react-router-dom';
import {routes} from '../routes';
import {useAuth} from './AuthProvider';

interface Props extends RouteProps {
    component: React.ComponentType<RouteComponentProps>;
}

const isAllowedDomain = (email: string | null): boolean => {
    if (email === null) {
        return false;
    }

    return email.endsWith('@merkkur.de') || email.endsWith('@jakkaro.de');
};

export function ProtectedRoute(props: Props): ReactElement<Props> {
    const {component: ProtectedComponent, ...rest} = props;
    const auth = useAuth();

    const component = (targetProps: RouteComponentProps) => {
        if (auth.firebaseAuth === null) {
            return <Redirect to={routes.LOGIN} />;
        }

        if (!auth.firebaseAuth.emailVerified) {
            return <Redirect to={routes.VERIFICATION} />;
        }

        if (isAllowedDomain(auth.firebaseAuth.email)) {
            return <ProtectedComponent {...targetProps} />;
        }

        return <Redirect to={routes.UNAUTHORIZED} />;
    };

    return <Route {...rest} render={component} />;
}
