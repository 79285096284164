import * as firebase from 'firebase';
import {getEnv} from '../../../../core/helpers';
import axios from 'axios';

export async function applyAllUpdates(params: {importId: string; auth: firebase.User}) {
    const {importId, auth} = params;
    try {
        const baseUrl = getEnv('REACT_APP_FRONTEND_URL');
        const token = await auth.getIdToken();
        const url = `${baseUrl}/product-import/v2/${importId}/updates/apply`;
        await axios.get(url, {
            headers: {Authorization: 'Bearer ' + token}
        });
    } catch (e) {
        console.error(e);
    }
}

export async function deleteUpdatesForType(params: {
    importId: string;
    type: 'product' | 'article' | 'variation';
    auth: firebase.User;
}) {
    const {importId, auth, type} = params;
    try {
        const baseUrl = getEnv('REACT_APP_FRONTEND_URL');
        const token = await auth.getIdToken();
        const url = `${baseUrl}/product-import/v2/${importId}/updates/delete?type=${type}`;
        await axios.get(url, {
            headers: {Authorization: 'Bearer ' + token}
        });
    } catch (e) {
        console.error(e);
    }
}

export async function deleteUpdatesForTypeAndField(params: {
    importId: string;
    type: 'product' | 'article' | 'variation';
    fieldName: string;
    auth: firebase.User;
}) {
    const {importId, auth, type, fieldName} = params;
    try {
        const baseUrl = getEnv('REACT_APP_FRONTEND_URL');
        const token = await auth.getIdToken();
        const url = `${baseUrl}/product-import/v2/${importId}/updates/delete?type=${type}&fieldName=${fieldName}`;
        await axios.get(url, {
            headers: {Authorization: 'Bearer ' + token}
        });
    } catch (e) {
        console.error(e);
    }
}

export async function applyUpdatesForTypeAndField(params: {
    importId: string;
    type: 'product' | 'article' | 'variation';
    fieldName: string;
    auth: firebase.User;
}) {
    const {importId, auth, type, fieldName} = params;
    try {
        const baseUrl = getEnv('REACT_APP_FRONTEND_URL');
        const token = await auth.getIdToken();
        const url = `${baseUrl}/product-import/v2/${importId}/updates/apply?type=${type}&fieldName=${fieldName}`;
        await axios.get(url, {
            headers: {Authorization: 'Bearer ' + token}
        });
    } catch (e) {
        console.error(e);
    }
}
