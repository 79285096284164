import React from 'react';
import {ImportsOverviewContainer} from './ImportOverview/ImportsOverviewContainer';
import {UnmatchedSkuExport} from './UnmatchedSkuExport';
import {useStyles} from './styles';
import {ProductDataExport} from './ProductDataExport/ProductDataExport';
import {toggles} from '../core/toggles';
import ProductDataExportFromBackend from './ProductDataExportFromBackend';

export const StartPage = () => {
    const classes = useStyles();

    const productDataExport = toggles.useExportFromBackend ? ProductDataExportFromBackend() : ProductDataExport();

    return (
        <div className={classes.myPaper}>
            <ImportsOverviewContainer />
            {productDataExport}
            <UnmatchedSkuExport />
        </div>
    );
};
