import React, {ReactNode} from 'react';
import {useAuth} from '../auth/AuthProvider';
import {SideNav} from '../SideNav/SideNav';
import {TopBar} from '../TopBar/TopBar';

interface Props {
    children: ReactNode;
}

export const AppFrame = (props: Props) => {
    const auth = useAuth();

    if (auth.firebaseAuth && auth.firebaseAuth.emailVerified) {
        return (
            <>
                <TopBar />
                <SideNav />
                <div className="frame-padding full-width">{props.children}</div>
            </>
        );
    } else {
        return <div className="align-center">{props.children}</div>;
    }
};
