import Paper from '@material-ui/core/Paper';
import React from 'react';
import {ImportSuccess} from '../../../core/model/ImportSuccess';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    container: {display: 'flex', flexDirection: 'column', margin: '5px 0px'},
    panel: {margin: '5px', padding: '10px'}
}));

export const SuccessesPanel = (props: {pendingFile: ImportSuccess}) => {
    const classes = useStyles();

    return (
        <div className={classes.container} role={'tabpanel'} aria-label={`${props.pendingFile.name}-updates-tab`}>
            <Paper className={classes.panel}>
                <h4>
                    Es wurden {props.pendingFile.importedSkus} neue Produkte beim Import von {props.pendingFile.name}{' '}
                    angelegt.
                </h4>
            </Paper>
        </div>
    );
};
