import {getEnv} from './helpers';
import axios from 'axios';

export async function reportError(props: {message: string}) {
    try {
        const baseUrl = getEnv('REACT_APP_FRONTEND_URL');
        const url = `${baseUrl}/report-error`;
        await axios.post(url, {
            message: props.message
        });
    } catch (e) {
        console.error(e);
    }
}
