import {fbCaduceus} from '../../core/firebase';

export function fiveMostRecentImports() {
    return fbCaduceus
        .firestore()
        .collection('product_data_incoming_files')
        .orderBy('uploadedAt', 'desc')
        .limit(5);
}

export function updatesForFieldQuery(importId: string, field: string, limit: number) {
    return fbCaduceus
        .firestore()
        .collection(`product_data_incoming_files/${importId}/updates`)
        .where(`keys`, 'array-contains', field)
        .limit(limit);
}
