import React from 'react';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Badge from '@material-ui/core/Badge';

export const StatusIndicators = (props: {errorCount: number}) => {
    return props.errorCount === 0 ? (
        <CheckCircle
            aria-label={'Import ohne Fehler.'}
            style={{verticalAlign: 'middle'}}
            htmlColor={'green'}
            titleAccess={'Import ohne Fehler.'}
        />
    ) : (
        <Badge badgeContent={props.errorCount} color="error">
            <CheckCircleOutline
                aria-label={`Import mit ${props.errorCount} Fehlern.`}
                style={{verticalAlign: 'middle'}}
                htmlColor={'orange'}
                titleAccess={`Import mit ${props.errorCount} Fehlern.`}
            />
        </Badge>
    );
};
