import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import * as firebase from 'firebase/app';
// import 'firebase/auth';
import * as React from 'react';
import {UserMenu} from './UserMenu';
import {useState} from 'react';
import {fbCaduceus} from '../../core/firebase';

export const UserControl = () => {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);

    const openMenu = (event: React.MouseEvent) => {
        const targetElement = event.currentTarget as HTMLElement;
        setAnchorElement(targetElement);
    };

    const closeMenu = () => {
        setAnchorElement(null);
    };

    const logoutHandler = (): Promise<void> => {
        return fbCaduceus.auth().signOut();
    };

    const open = anchorElement !== null;

    return (
        <>
            <IconButton
                data-testid={'user-menu-button'}
                data-qa="user-menu"
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={openMenu}
                color="inherit">
                <AccountCircle />
            </IconButton>
            <UserMenu
                data-testid={'user-menu'}
                anchorElement={anchorElement}
                onCloseHandler={closeMenu}
                logoutHandler={logoutHandler}
            />
        </>
    );
};
