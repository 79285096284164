import {ImportSuccess, ValidationError} from '../../../core/model/ImportSuccess';
import {chain as _} from 'lodash';
import Paper from '@material-ui/core/Paper';
import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    container: {display: 'flex', flexDirection: 'column', margin: '5px 0px'},
    overviewSection: {display: 'flex', flexDirection: 'row'},
    smallPanel: {display: 'flex', flexDirection: 'column', flex: '0 1 250px', margin: '5px', padding: '10px'},
    largePanel: {display: 'flex', flexDirection: 'column', flex: '0 1 500px', margin: '5px', padding: '10px'},
    fillerPanel: {display: 'flex', flexDirection: 'column', flex: '1 1 500px', margin: '5px', padding: '10px'},
    paperBuffers: {margin: '5px', padding: '10px'},
    paperHeading: {margin: 0},
    headingGrow: {margin: 0, flex: 1},
    clickable: {cursor: 'pointer', '&:hover': {background: '#eee'}},
    detailPanel: {display: 'flex', flexDirection: 'row', flex: '1'}
}));

export const ErrorPanel = (props: {pendingFile: ImportSuccess}) => {
    const classes = useStyles();
    const [selectedReason, setReason] = useState<string | null>(null);

    const errorsByReason = _(props.pendingFile.errors)
        .groupBy(it => it.reason)
        .value();

    const selectReason = (reason: string) => {
        return () => {
            setReason(reason);
        };
    };

    const errorMetrics = _(Object.keys(errorsByReason))
        .sortBy(reason => errorsByReason[reason].length)
        .reverse()
        .map(reason => {
            return (
                <div key={reason} onClick={selectReason(reason)} className={classes.clickable}>
                    <Metric label={reason} value={errorsByReason[reason].length} />
                </div>
            );
        })
        .value();

    const errors = Object.keys(errorsByReason).length === 0 ? <span>-</span> : errorMetrics;

    const detailPanel = selectedReason !== null ? <AffectedSkus errors={errorsByReason[selectedReason]} /> : null;

    return (
        <div className={classes.container} role={'tabpanel'} aria-label={`${props.pendingFile.name}-error-tab`}>
            <div className={classes.overviewSection}>
                <Paper className={classes.largePanel}>
                    <h4 className={classes.paperHeading}>Fehler</h4>
                    {errors}
                </Paper>
                {detailPanel}
            </div>
        </div>
    );
};

const AffectedSkus = (props: {errors: ValidationError[]}) => {
    const classes = useStyles();

    const rows = _(props.errors)
        .map((it: ValidationError, index: number) => {
            const value = it.value !== '' ? it.value : '-';

            return (
                <div data-testid={`row-${it.sku}`} key={index} style={{display: 'flex', flexDirection: 'row'}}>
                    <span style={{flex: 1}}>{it.sku}</span>
                    <span style={{flex: 1}}>{it.fieldName}</span>
                    <span style={{flex: 1}}>&#39;{value}&#39;</span>
                </div>
            );
        })
        .value();

    return (
        <Paper data-testid={'detail-panel'} className={classes.fillerPanel}>
            <div className={classes.detailPanel}>
                <h4 className={classes.headingGrow}>Betroffene SKU</h4>
                <h4 className={classes.headingGrow}>Feld</h4>
                <h4 className={classes.headingGrow}>Wert</h4>
            </div>
            {rows}
        </Paper>
    );
};

const Metric = (props: {label: string; value: number}) => {
    return (
        <div data-testid={props.label} style={{display: 'flex'}}>
            <span style={{flex: 1}}>{props.label}</span>
            <span>{props.value}</span>
        </div>
    );
};
