import Drawer from '@material-ui/core/Drawer';
import React, {forwardRef, Ref} from 'react';
import './SideNav.css';
import MenuList from '@material-ui/core/MenuList';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import {routes} from '../routes';
import {Link} from 'react-router-dom';

// eslint-disable-next-line react/display-name
const ForwardRef = forwardRef((props: any, ref: Ref<any>) => <Link {...props} innerRef={ref} />);

export const SideNav = () => {
    return (
        <Drawer className={'drawer'} variant="permanent">
            <MenuList>
                <ListItem component={'span'} className={'sideNavSectionTitle'} button={false}>
                    Basisdaten
                </ListItem>
                <MenuItem to={routes.PRODUCTDATA_MAIN} component={ForwardRef} button={true}>
                    Dropbox Import
                </MenuItem>
                <MenuItem to={routes.PRODUCTDATA_MAIN} component={ForwardRef} button={true}>
                    Excel Download
                </MenuItem>
                <MenuItem to={routes.PRODUCTDATA_MAIN} component={ForwardRef} button={true}>
                    Unzuweisbare SKUs
                </MenuItem>
            </MenuList>
        </Drawer>
    );
};
