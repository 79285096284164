import {fbCaduceus} from './firebase';
import {DocumentReference} from './typeAliases';

export const firestoreClient = {
    getDocumentReference(collection: string, documentId: string): DocumentReference {
        return fbCaduceus
            .firestore()
            .collection(collection)
            .doc(documentId);
    }
};
