import Paper from '@material-ui/core/Paper';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {UnconfirmedUpdate} from './model/UnconfirmedUpdate';
import {ButtonWithProgress} from '../../../components/ButtonWithProgress';
import {useAuth} from '../../../auth/AuthProvider';
import * as updateClient from './service/updateClient';
import {IconButtonWithProgress} from '../../../components/IconButtonWithProgress';
import Delete from '@material-ui/icons/Delete';
import {frontendClient} from '../../../core/frontendClient';
import Done from '@material-ui/icons/Done';

const useStyles = makeStyles(() => ({
    root: {width: '100%', height: '100%', padding: '10px'},
    heading: {fontWeight: 'bold', margin: '10px 0'},
    clickable: {cursor: 'pointer', '&:hover': {background: '#eee'}}
}));

export function NewOverviewTile(props: {
    importId: string;
    productUpdates: UnconfirmedUpdate[];
    articleUpdates: UnconfirmedUpdate[];
    variationUpdates: UnconfirmedUpdate[];
    selectGroup: (group: 'product' | 'article' | 'variation', updates: UnconfirmedUpdate[]) => void;
}) {
    const classes = useStyles();
    const auth = useAuth().firebaseAuth!!;

    const deleteUpdates = (type: 'product' | 'article' | 'variation') =>
        updateClient.deleteUpdatesForType({
            importId: props.importId,
            type: type,
            auth: auth
        });

    const applyUpdatesForType = (type: 'product' | 'article' | 'variation') =>
        frontendClient.applyUpdatesForType({
            importId: props.importId,
            type: type,
            auth: auth
        });

    return (
        <Paper className={classes.root}>
            <div className={classes.heading}>Updates</div>
            <div
                className={classes.clickable}
                onClick={() => props.selectGroup('product', props.productUpdates)}
                key="productUpdates"
                style={{display: 'flex', alignItems: 'center', height: '28px'}}>
                <span style={{flex: 1}}>Parent Updates</span>
                <span>{props.productUpdates.length}</span>
                <span style={{marginLeft: '5px', display: 'flex'}}>
                    <IconButtonWithProgress style={{padding: 0}} action={() => applyUpdatesForType('product')}>
                        <Done />
                    </IconButtonWithProgress>
                    <IconButtonWithProgress style={{padding: 0}} action={() => deleteUpdates('product')}>
                        <Delete />
                    </IconButtonWithProgress>
                </span>
            </div>
            <div
                className={classes.clickable}
                onClick={() => props.selectGroup('article', props.articleUpdates)}
                key="articleUpdates"
                style={{display: 'flex', alignItems: 'center', height: '28px'}}>
                <span style={{flex: 1}}>Teen Updates</span>
                <span>{props.articleUpdates.length}</span>
                <span style={{marginLeft: '5px', display: 'flex'}}>
                    <IconButtonWithProgress style={{padding: 0}} action={() => applyUpdatesForType('article')}>
                        <Done />
                    </IconButtonWithProgress>
                    <IconButtonWithProgress style={{padding: 0}} action={() => deleteUpdates('article')}>
                        <Delete />
                    </IconButtonWithProgress>
                </span>
            </div>
            <div
                className={classes.clickable}
                onClick={() => props.selectGroup('variation', props.variationUpdates)}
                key="variationUpdates"
                style={{display: 'flex', alignItems: 'center', height: '28px'}}>
                <span style={{flex: 1}}>Child Updates</span>
                <span>{props.variationUpdates.length}</span>
                <span style={{marginLeft: '5px', display: 'flex'}}>
                    <IconButtonWithProgress style={{padding: 0}} action={() => applyUpdatesForType('variation')}>
                        <Done />
                    </IconButtonWithProgress>
                    <IconButtonWithProgress style={{padding: 0}} action={() => deleteUpdates('variation')}>
                        <Delete />
                    </IconButtonWithProgress>
                </span>
            </div>
            <ButtonWithProgress
                aria-label={'Updates Verwerfen'}
                variant={'outlined'}
                color={'secondary'}
                action={() => frontendClient.deleteAllUpdatesV2({importId: props.importId, auth: auth})}>
                Alle Verwerfen
            </ButtonWithProgress>
            <ButtonWithProgress
                aria-label={'Updates Anwenden'}
                variant={'contained'}
                color={'primary'}
                action={() => updateClient.applyAllUpdates({importId: props.importId, auth: auth})}>
                Alle Anwenden
            </ButtonWithProgress>
        </Paper>
    );
}
