import React, {useState} from 'react';
import {ImportSuccess} from '../../../../core/model/ImportSuccess';
import {makeStyles} from '@material-ui/styles';
import {UpdatesOverviewTile} from './UpdatesOverviewTile';
import Paper from '@material-ui/core/Paper';
import {UpdatesForFieldTile} from './UpdatesForFieldTile';

const useStyles = makeStyles(() => ({
    container: {display: 'flex', flexDirection: 'row', margin: '5px 0px'},
    panel: {margin: '5px', padding: '10px'},
    smallPanel: {display: 'flex', flexDirection: 'column', flex: '0 1 300px', margin: '5px'}
}));

export const UpdatesPanel = (props: {pendingFile: ImportSuccess}) => {
    const classes = useStyles();

    const [field, setField] = useState<string | null>(null);

    return (
        <div className={classes.container} role={'tabpanel'} aria-label={`${props.pendingFile.name}-updates-tab`}>
            <div className={classes.smallPanel}>
                <UpdatesOverviewTile
                    importId={props.pendingFile.id}
                    updateCounts={props.pendingFile.updateCounts!!}
                    affectedSkus={props.pendingFile.unresolvedUpdates!!}
                    selectField={(key: string) => () => setField(key)}
                />
            </div>
            <div className={classes.smallPanel}>
                {field !== null && field in props.pendingFile.updateCounts!! ? (
                    <UpdatesForFieldTile importId={props.pendingFile.id} field={field} />
                ) : (
                    <Paper style={{width: '100%', height: '100%', padding: '10px'}}>
                        <p>Um details zu sehen, w&auml;hle ein Feld aus der Update&uuml;bersicht aus.</p>
                    </Paper>
                )}
            </div>
        </div>
    );
};
