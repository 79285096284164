import {fbCaduceus} from './firebase';
import {HttpsCallableResult} from './typeAliases';

const getCallable = (functionName: string) => {
    return fbCaduceus.functions('europe-west1').httpsCallable(functionName);
};

export const functionsClient = {
    _refs: {
        prepareExport: getCallable('main-http-prepareProductDataExport'),
        applyUpdates: getCallable('main-productImport-applyUpdates'),
        prepareUnmatchedExport: getCallable('main-http-prepareUnmatchedProductsExport')
    },

    prepareExport: (): Promise<HttpsCallableResult> => {
        return functionsClient._refs.prepareExport();
    },

    prepareUnmatchedAmzExport: (): Promise<HttpsCallableResult> => {
        return functionsClient._refs.prepareUnmatchedExport();
    },

    applyUpdates: (importId: string): Promise<HttpsCallableResult> => {
        return functionsClient._refs.applyUpdates({importId});
    }
};
