import React, {SyntheticEvent} from 'react';
import {SvgIconProps} from '@material-ui/core/SvgIcon';
import {makeStyles} from '@material-ui/styles';
import Chip from '@material-ui/core/Chip';
import IconButton from '@material-ui/core/IconButton';
import {toggles} from '../../core/toggles';

const useStyles = makeStyles(() => ({
    inactive: {
        margin: '2px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#444',
            color: '#fdc508'
        }
    }
}));

interface Props {
    icon: React.ComponentType<SvgIconProps>;
    value: number;
    clickHandler: React.EventHandler<SyntheticEvent>;
    label: string;
}

export const ImportResultIndicator = (props: Props) => {
    const classes = useStyles();

    if (props.value === 0) {
        return null;
    }
    const Icon = props.icon;

    if (toggles.useUpdatesFromBackend) {
        return (
            <IconButton
                title={props.label}
                role={'tab'}
                aria-label={props.label}
                className={classes.inactive}
                color="secondary"
                onClick={props.clickHandler}>
                <Icon />
            </IconButton>
        );
    } else {
        return (
            <Chip
                title={props.label}
                role={'tab'}
                aria-label={props.label}
                color={'primary'}
                className={classes.inactive}
                icon={<Icon />}
                label={props.value}
                onClick={props.clickHandler}
            />
        );
    }
};
