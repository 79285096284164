import * as firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

declare global {
    interface Window {
        cypressFirebase: firebase.app.App;
        Cypress: any;
    }
}

function initializeNewFirebaseInstance() {
    return firebase.initializeApp({
        apiKey: process.env.REACT_APP_FIREBASE_apiKey,
        authDomain: process.env.REACT_APP_FIREBASE_authDomain,
        databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
        projectId: process.env.REACT_APP_FIREBASE_projectId,
        storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
        messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId
    });
}

const firebaseInstance = firebase.apps.length !== 0 ? firebase.apps[0] : initializeNewFirebaseInstance();

if (window.Cypress) {
    window.cypressFirebase = firebaseInstance;
}

export const fbCaduceus: firebase.app.App = firebaseInstance;
