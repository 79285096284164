export const routes = {
    HOME: '/',
    LOGIN: '/login',
    REGISTRATION: '/register',
    LOGOUT: '/logout',
    VERIFICATION: '/verify',
    UNAUTHORIZED: '/unauthorized',
    PRODUCTDATA_MAIN: '/product-data',
    PRODUCTDATA_INVALIDSKUS: '/product-data/invalid',
    PRODUCTDATA_DUPLICATESKUS: '/product-data/duplicate',
    PRODUCTDATA_IMPORT_DETAIL: '/product-data/import'
};
