import {UnconfirmedUpdate} from './model/UnconfirmedUpdate';
import {useCallback, useReducer} from 'react';

type Action =
    | {type: 'selectField'; fieldName: string}
    | {type: 'selectGroup'; group: 'product' | 'article' | 'variation'; updates: UnconfirmedUpdate[]};

interface State {
    selectedGroup: 'product' | 'article' | 'variation' | null;
    selectedField: string | null;
    selectedUpdates: UnconfirmedUpdate[];
}

function updatePanelReducer(state: State, action: Action): State {
    switch (action.type) {
        case 'selectField':
            return {...state, selectedField: action.fieldName};
        case 'selectGroup':
            return {
                ...state,
                selectedUpdates: action.updates,
                selectedGroup: action.group,
                selectedField: null
            };
        default:
            return state;
    }
}

export function useUpdatePanelReducer() {
    const initialState = {
        selectedGroup: null,
        selectedField: null,
        selectedUpdates: []
    };

    const [state, dispatch] = useReducer(updatePanelReducer, initialState);

    const selectGroup = useCallback((group: 'product' | 'article' | 'variation', updates: UnconfirmedUpdate[]) => {
        dispatch({type: 'selectGroup', group, updates});
    }, []);

    const selectField = useCallback((fieldName: string) => {
        dispatch({type: 'selectField', fieldName});
    }, []);

    return {
        state,
        selectGroup,
        selectField
    };
}
