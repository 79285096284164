import {firestore} from 'firebase';
import {toggles} from '../toggles';

export interface ImportSuccess {
    id: string;
    name: string;
    size: number;
    uploadedAt: firestore.Timestamp;
    importedSkus: number;
    unresolvedUpdates?: number;
    knownProducts: number;
    updateCounts?: {[key: string]: number};
    errors: ValidationError[];
    containedSkus: number;
    counts?: {
        total: number;
        imported: number;
        updates: number;
        errors: number;
    };
}

export interface ValidationError {
    fieldName: string;
    value: string;
    reason: string;
    sku: string;
}

// "any"-type is appropriate for typeguards
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isImportSuccess(object: any): object is ImportSuccess {
    if (toggles.useUpdatesFromBackend) {
        return (
            'id' in object &&
            'name' in object &&
            'size' in object &&
            'uploadedAt' in object &&
            'errors' in object &&
            'importedSkus' in object &&
            'containedSkus' in object &&
            'counts' in object
        );
    } else {
        return (
            'id' in object &&
            'name' in object &&
            'size' in object &&
            'uploadedAt' in object &&
            'unresolvedUpdates' in object &&
            'errors' in object &&
            'importedSkus' in object &&
            'containedSkus' in object
        );
    }
}
