import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as React from 'react';

interface Props {
    anchorElement: HTMLElement | null;
    onCloseHandler: () => void;
    logoutHandler: () => void;
}

export const UserMenu = (props: Props) => {
    const open = props.anchorElement !== null;

    return (
        <Menu
            id="menu-appbar"
            anchorEl={props.anchorElement}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
            open={open}
            onClose={props.onCloseHandler}>
            <MenuItem data-qa={'logout-button'} onClick={props.logoutHandler}>
                Logout
            </MenuItem>
        </Menu>
    );
};
