import {useEffect, useState} from 'react';
import {firestoreClient} from '../../core/firestoreClient';
import {storageClient} from '../../core/storageClient';
import {DocumentSnapshot, Timestamp} from '../../core/typeAliases';

export interface FetchDataState<T> {
    isLoading: boolean;
    data: T | null;
}

export interface ExportInfo {
    products: number;
    updated: Timestamp;
    downloadUrl: string;
}

export const productDataExportService = {
    useExportInfo(exportName: string): FetchDataState<ExportInfo> {
        const [exportInfoState, setExportInfoState] = useState<FetchDataState<ExportInfo>>({
            isLoading: true,
            data: null
        });

        useEffect(() => {
            return firestoreClient
                .getDocumentReference('file_metadata', exportName)
                .onSnapshot(async (doc: DocumentSnapshot) => {
                    const data = doc.data();

                    if (data) {
                        const downloadUrl = await storageClient.getDownloadURL(data.path);

                        setExportInfoState({
                            isLoading: false,
                            data: {
                                downloadUrl: downloadUrl,
                                products: data.products,
                                updated: data.updated
                            }
                        });
                    } else {
                        const noData = {
                            isLoading: false,
                            data: null
                        };
                        setExportInfoState(noData);
                    }
                });
        }, [exportName]);

        return exportInfoState;
    }
};
