import React, {FormEvent, useReducer} from 'react';
import './RegistrationPage.css';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import {useAuth} from '../AuthProvider';
import {useHistory} from 'react-router-dom';
import {routes} from '../../routes';

type FieldName = 'email' | 'password' | 'passwordConfirm';
type Action =
    | {type: 'setValue'; fieldName: FieldName; value: string}
    | {type: 'setError'; fieldName: FieldName; error: string | null}
    | {type: 'setSubmitError'; error: Error | null};

interface FieldState {
    value: string;
    error: string | null;
}

interface State {
    email: FieldState;
    password: FieldState;
    passwordConfirm: FieldState;
    submitError: Error | null;
}

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'setSubmitError':
            return {
                ...state,
                submitError: action.error
            };
        case 'setError':
            return {
                ...state,
                [action.fieldName]: {
                    ...state[action.fieldName],
                    error: action.error
                }
            };
        case 'setValue':
            return {
                ...state,
                [action.fieldName]: {
                    ...state[action.fieldName],
                    value: action.value,
                    error: null
                }
            };
    }
}

const initialState = {
    email: {value: '', error: null},
    password: {value: '', error: null},
    passwordConfirm: {value: '', error: null},
    submitError: null
};

function useRegistrationState() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const auth = useAuth();
    const history = useHistory();

    async function register() {
        try {
            await auth.register({email: state.email.value, password: state.password.value});
            history.push(routes.HOME);
        } catch (error) {
            dispatch({type: 'setSubmitError', error});
        }
    }

    function setValue(fieldName: FieldName, value: string) {
        dispatch({type: 'setValue', fieldName, value});
    }

    function setError(fieldName: FieldName, error: string) {
        dispatch({type: 'setError', fieldName, error});
    }

    function validateEmail() {
        const isMerkkur = state.email.value.endsWith('@merkkur.de');
        const isJakkaro = state.email.value.endsWith('@jakkaro.de');

        if (!(isMerkkur || isJakkaro)) {
            setError('email', 'Muss eine merkkur oder jakkaro Email sein.');
        }
    }

    function validatePassword() {
        if (state.password.value.length < 8) {
            setError('password', 'Passwort ist kürzer als 8 Zeichen.');
        }

        const digit = /[0-9]/;
        if (!digit.test(state.password.value)) {
            setError('password', 'Passwort enthält keine Zahl.');
        }

        const specialCharacter = /[^A-Za-z0-9]/g;
        if (!specialCharacter.test(state.password.value)) {
            setError('password', 'Passwort enthält kein Sonderzeichen.');
        }
    }

    function validatePasswordConfirm() {
        if (state.password.value !== state.passwordConfirm.value) {
            setError('passwordConfirm', 'Die eingegebenen Passwörter stimmen nicht überein.');
        }
    }

    const isEmpty = state.email.value === '' || state.password.value === '' || state.passwordConfirm.value === '';
    const noErrors =
        state.email.error === null && state.password.error === null && state.passwordConfirm.error === null;

    const isValid = !isEmpty && noErrors;

    return {
        state,
        setValue,
        validateEmail,
        validatePassword,
        validatePasswordConfirm,
        register,
        isValid
    };
}

function RegistrationPage() {
    const {
        state,
        setValue,
        validateEmail,
        validatePassword,
        validatePasswordConfirm,
        register,
        isValid
    } = useRegistrationState();

    async function onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!isValid) {
            return;
        }
        register();
    }

    return (
        <div className="wrapper">
            <Paper className="form-container">
                <Typography component="h1" variant="h5">
                    Neues Konto Erstellen:
                </Typography>
                <form onSubmit={onSubmit}>
                    <TextField
                        id="email"
                        name="email"
                        error={state.email.error !== null}
                        fullWidth={true}
                        required={true}
                        label="Email"
                        inputProps={{'aria-label': 'Email'}}
                        value={state.email.value}
                        onChange={event => setValue('email', event.target.value)}
                        onBlur={validateEmail}
                        margin="normal"
                        variant="outlined"
                        helperText={
                            state.email.error ? state.email.error : 'Muss eine merkkur oder jakkaro Email sein.'
                        }
                    />
                    <TextField
                        id="password"
                        name="password"
                        error={state.password.error !== null}
                        type="password"
                        fullWidth={true}
                        required={true}
                        label="Passwort"
                        inputProps={{'aria-label': 'Passwort'}}
                        value={state.password.value}
                        onChange={event => setValue('password', event.target.value)}
                        onBlur={validatePassword}
                        margin="normal"
                        variant="outlined"
                        helperText={
                            state.password.error ? state.password.error : 'Min. 8 Zeichen, 1 Zahl, 1 Sonderzeichen.'
                        }
                    />
                    <TextField
                        id="password-confirm"
                        name="password-confirm"
                        error={state.passwordConfirm.error !== null}
                        type="password"
                        fullWidth={true}
                        required={true}
                        label="Passwort bestätigen"
                        inputProps={{'aria-label': 'Passwort bestätigen'}}
                        value={state.passwordConfirm.value}
                        onChange={event => setValue('passwordConfirm', event.target.value)}
                        onBlur={validatePasswordConfirm}
                        margin="normal"
                        variant="outlined"
                        helperText={
                            state.passwordConfirm.error
                                ? state.passwordConfirm.error
                                : 'Bitte tippe dein Passwort erneut.'
                        }
                    />
                    <Button
                        data-qa={'submit-button'}
                        aria-label={'absenden'}
                        disabled={!isValid}
                        variant="contained"
                        type="submit"
                        fullWidth={true}>
                        Absenden
                    </Button>
                    {state.submitError && <p className={'error-message'}>{state.submitError.message}</p>}
                </form>
            </Paper>
        </div>
    );
}

export default RegistrationPage;
