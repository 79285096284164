import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, {ChangeEvent, FormEvent} from 'react';
import {Link, RouteComponentProps} from 'react-router-dom';
import {routes} from '../routes';
import './LoginPage.css';
import {fbCaduceus} from '../core/firebase';

export interface State {
    email: string;
    password: string;
    error: Error | null;
}

const initialState: State = {
    email: '',
    password: '',
    error: null
};

export class LoginPage extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);

        this.state = initialState;
    }

    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const {email, password} = this.state;
        try {
            await fbCaduceus.auth().signInWithEmailAndPassword(email, password);
            this.props.history.replace(routes.HOME);
        } catch (error) {
            this.setState({...this.state, error});
        }
    };

    onChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({...this.state, ...{[event.target.name]: event.target.value}});
    };

    render() {
        const {email, password, error} = this.state;

        return (
            <div className="wrapper">
                <Paper className="form-container">
                    <Typography component="h2" variant="h5">
                        Bitte logge dich ein, um Caduceus zu nutzen.
                    </Typography>
                    <form onSubmit={this.onSubmit}>
                        <TextField
                            id="email"
                            name="email"
                            label="Email"
                            inputProps={{'aria-label': 'Email'}}
                            required={true}
                            fullWidth={true}
                            value={email}
                            onChange={this.onChange}
                            type="text"
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            id="password"
                            name="password"
                            label="Passwort"
                            inputProps={{'aria-label': 'Passwort'}}
                            fullWidth={true}
                            required={true}
                            value={password}
                            onChange={this.onChange}
                            type="password"
                            margin="normal"
                            variant="outlined"
                        />
                        <Button
                            data-qa={'login-button'}
                            aria-label={'absenden'}
                            variant="contained"
                            type="submit"
                            fullWidth={true}>
                            Login
                        </Button>
                        {error && (
                            <p className={'error-message'} aria-label={'fehler'}>
                                {error.message}
                            </p>
                        )}
                    </form>
                    <p>
                        Wenn du noch keinen Zugang hast, kannst du dich{' '}
                        <Link to={routes.REGISTRATION}>registrieren</Link>.
                    </p>
                </Paper>
            </div>
        );
    }
}
