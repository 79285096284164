import * as firebase from 'firebase/app';
import React, {ReactNode, useEffect, useState} from 'react';
import {fbCaduceus} from '../core/firebase';
import {register} from './authService';

const defaultState = {
    register,
    firebaseAuth: null
};
const AuthContext = React.createContext<{
    register: (params: {email: string; password: string}) => Promise<void>;
    firebaseAuth: firebase.User | null;
}>(defaultState);

const authFromLocalStorage = (): firebase.User | null => {
    const user = localStorage.getItem('authedUser');

    if (user !== null) {
        return JSON.parse(user) as firebase.User;
    } else {
        return null;
    }
};

function AuthProvider(props: {children: ReactNode}) {
    const [auth, setAuth] = useState<firebase.User | null>(authFromLocalStorage());

    useEffect(() => {
        return fbCaduceus.auth().onAuthStateChanged((firebaseAuth: firebase.User | null) => {
            if (firebaseAuth !== null) {
                localStorage.setItem('authedUser', JSON.stringify(firebaseAuth));
            } else {
                localStorage.removeItem('authedUser');
            }
            setAuth(firebaseAuth);
        });
    }, []);

    return <AuthContext.Provider value={{...defaultState, firebaseAuth: auth}}>{props.children}</AuthContext.Provider>;
}

const useAuth = () => React.useContext(AuthContext);
export {AuthProvider, useAuth};
