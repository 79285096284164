import Paper from '@material-ui/core/Paper';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {chain} from 'lodash';
import {UnconfirmedUpdate} from './model/UnconfirmedUpdate';
import * as updateClient from './service/updateClient';
import {IconButtonWithProgress} from '../../../components/IconButtonWithProgress';
import Delete from '@material-ui/icons/Delete';
import Done from '@material-ui/icons/Done';
import {useAuth} from '../../../auth/AuthProvider';

const useStyles = makeStyles(() => ({
    root: {width: '100%', height: '100%', padding: '10px'},
    heading: {fontWeight: 'bold', margin: '10px 0'},
    warning: {
        backgroundColor: '#ffe5e5',
        border: '1px solid #ff6464',
        padding: '3px',
        borderRadius: '3px',
        color: 'red',
        fontSize: '13px',
        marginBottom: '10px'
    },
    clickable: {cursor: 'pointer', '&:hover': {background: '#eee'}}
}));

export function UpdatesForGroupTile(props: {
    importId: string;
    updates: UnconfirmedUpdate[];
    type: 'product' | 'article' | 'variation';
    selectField: (field: string) => void;
}) {
    const classes = useStyles();
    const auth = useAuth().firebaseAuth!!;

    if (props.updates.length === 0) {
        return null;
    }

    const updateCountByFieldName = chain(props.updates)
        .flatMap(it => it.keys)
        .countBy(it => it)
        .value();

    const updatesByNewValue = Object.keys(updateCountByFieldName).map(fieldName => {
        return (
            <div
                className={classes.clickable}
                onClick={() => props.selectField(fieldName)}
                key={fieldName}
                style={{display: 'flex'}}>
                <span style={{flex: 1}}>{fieldName}</span>
                <span>{updateCountByFieldName[fieldName]}</span>
                <span style={{marginLeft: '5px', display: 'flex'}}>
                    <IconButtonWithProgress
                        style={{padding: 0}}
                        action={() =>
                            updateClient.applyUpdatesForTypeAndField({
                                importId: props.importId,
                                type: props.type,
                                fieldName,
                                auth: auth
                            })
                        }>
                        <Done />
                    </IconButtonWithProgress>
                </span>
                <span style={{marginLeft: '5px', display: 'flex'}}>
                    <IconButtonWithProgress
                        style={{padding: 0}}
                        action={() =>
                            updateClient.deleteUpdatesForTypeAndField({
                                importId: props.importId,
                                type: props.type,
                                fieldName,
                                auth: auth
                            })
                        }>
                        <Delete />
                    </IconButtonWithProgress>
                </span>
            </div>
        );
    });

    return (
        <Paper className={classes.root} aria-label={`updates-for-field`}>
            <div className={classes.heading}>Updates pro Merkmal</div>
            {updatesByNewValue}
        </Paper>
    );
}
