import {isImportSuccess, ImportSuccess} from '../../core/model/ImportSuccess';
import React, {ReactElement, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';
import {StatusIndicators} from './StatusIndicators';
import {ImportResultIndicator} from './ImportResultIndicator';
import {SuccessesPanel} from './SuccessesPanel/SuccessesPanel';
import {ErrorPanel} from './ErrorPanel/ErrorPanel';
import Collapse from '@material-ui/core/Collapse';
import SwapHorizontalCircle from '@material-ui/icons/SwapHorizontalCircle';
import Error from '@material-ui/icons/Error';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CheckCircleOutlined from '@material-ui/icons/CheckCircleOutlined';
import {makeStyles} from '@material-ui/styles';
import {FailedImport} from '../../core/model/FailedImport';
import Chip from '@material-ui/core/Chip';
import {toggles} from '../../core/toggles';
import {NewUpdatesPanel} from './UpdatePanel/NewUpdatesPanel';
import {UpdatesPanel} from './UpdatePanel/legacy/UpdatesPanel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import {Typography} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    clickable: {
        cursor: 'pointer'
    },
    marginLeft: {
        marginLeft: '20px'
    }
}));

interface ExpansionState {
    rowId: string;
    panelName: string;
}

function getPanel(panelName: string, pendingFile: ImportSuccess): ReactElement | null {
    switch (panelName) {
        case 'success':
            return <SuccessesPanel pendingFile={pendingFile} />;
        case 'already-known':
            return <SuccessesPanel pendingFile={pendingFile} />;
        case 'errors':
            return <ErrorPanel pendingFile={pendingFile} />;
        case 'updates':
            if (toggles.useUpdatesFromBackend) {
                return <NewUpdatesPanel pendingFile={pendingFile} />;
            } else {
                return <UpdatesPanel pendingFile={pendingFile} />;
            }
        default:
            return null;
    }
}

export function ImportsOverviewTable(props: {importResults: (ImportSuccess | FailedImport)[]}) {
    const [expansionState, setExpansionState] = useState<ExpansionState | null>(null);

    const rows = props.importResults.map((it: ImportSuccess | FailedImport) => {
        if (isImportSuccess(it)) {
            const toggleRow = (panelName: string) => {
                if (expansionState && expansionState.rowId === it.id && expansionState.panelName === panelName) {
                    setExpansionState(null);
                } else {
                    setExpansionState({rowId: it.id, panelName});
                }
            };

            const content =
                expansionState && expansionState.rowId === it.id ? getPanel(expansionState.panelName, it) : null;

            if (toggles.useUpdatesFromBackend) {
                if (
                    it.counts?.updates === 0 &&
                    expansionState &&
                    expansionState.panelName === 'updates' &&
                    content !== null
                ) {
                    setExpansionState(null);
                }
            } else {
                if (
                    it.unresolvedUpdates === 0 &&
                    expansionState &&
                    expansionState.panelName === 'updates' &&
                    content !== null
                ) {
                    setExpansionState(null);
                }
            }

            return (
                <ExpandablePendingFileOverview
                    pendingFile={it}
                    key={it.id}
                    toggleExpansion={toggleRow}
                    expandedContent={content}
                />
            );
        } else {
            return <ImportErrorOverview importErrorFile={it} key={it.id} />;
        }
    });

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>SKU&#39;s gesamt</TableCell>
                    <TableCell>Ergebnis</TableCell>
                    <TableCell>Hochgeladen</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
        </Table>
    );
}

const ExpandablePendingFileOverview = (props: {
    pendingFile: ImportSuccess;
    toggleExpansion: (action: string) => void;
    expandedContent: ReactElement | null;
}) => {
    const [previousExpansionContent, setPreviousExpansionContent] = useState<ReactElement | null>(null);

    const isExpanded = props.expandedContent !== null;
    const panelHasChanged = props.expandedContent !== previousExpansionContent;
    if (isExpanded && panelHasChanged) {
        setPreviousExpansionContent(props.expandedContent);
    }

    const classes = useStyles();

    const totalSkuCount = props.pendingFile.containedSkus;
    const uploadedDate = moment(props.pendingFile.uploadedAt.toDate()).format('dd DD.MM.YYYY, HH:mm');

    if (toggles.useUpdatesFromBackend) {
        const processedSkus = props.pendingFile.counts?.total ?? 0;
        const done = totalSkuCount === processedSkus;
        return (
            <>
                <TableRow hover={true}>
                    <TableCell>
                        {processedSkus === totalSkuCount ? (
                            <StatusIndicators errorCount={props.pendingFile.errors.length} />
                        ) : (
                            <CircularProgressWithLabel value={100 * (processedSkus / totalSkuCount)} />
                        )}
                    </TableCell>
                    <TableCell>
                        <span style={{verticalAlign: 'middle'}} className={classes.marginLeft}>
                            {props.pendingFile.name}
                        </span>
                    </TableCell>
                    <TableCell>{totalSkuCount}</TableCell>
                    <TableCell role={'tablist'}>
                        {done ? (
                            <>
                                <ImportResultIndicator
                                    label={'Details zu erfolgreich importierten Produkten anzeigen.'}
                                    icon={CheckCircle}
                                    value={props.pendingFile.importedSkus}
                                    clickHandler={() => props.toggleExpansion('success')}
                                />
                                <ImportResultIndicator
                                    label={'Details zu bereits bekannten Produkten anzeigen.'}
                                    icon={CheckCircleOutlined}
                                    value={props.pendingFile.knownProducts}
                                    clickHandler={() => props.toggleExpansion('already-known')}
                                />
                                <ImportResultIndicator
                                    label={'Details zu Importfehlern anzeigen.'}
                                    icon={Error}
                                    value={props.pendingFile.counts?.errors ?? 0}
                                    clickHandler={() => props.toggleExpansion('errors')}
                                />
                                <ImportResultIndicator
                                    label={'Details zu Produktupdates anzeigen.'}
                                    icon={SwapHorizontalCircle}
                                    value={props.pendingFile.counts?.updates ?? 0}
                                    clickHandler={() => props.toggleExpansion('updates')}
                                />
                            </>
                        ) : null}
                    </TableCell>
                    <TableCell>{uploadedDate}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0, backgroundColor: '#f5f5f5'}} colSpan={5}>
                        <Collapse in={isExpanded} unmountOnExit>
                            {props.expandedContent ? props.expandedContent : previousExpansionContent}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    } else {
        return (
            <>
                <TableRow hover={true}>
                    <TableCell>
                        <StatusIndicators errorCount={props.pendingFile.errors.length} />
                        <span style={{verticalAlign: 'middle'}} className={classes.marginLeft}>
                            {props.pendingFile.name}
                        </span>
                    </TableCell>
                    <TableCell>{totalSkuCount}</TableCell>
                    <TableCell role={'tablist'}>
                        <ImportResultIndicator
                            label={'Details zu erfolgreich importierten Produkten anzeigen.'}
                            icon={CheckCircle}
                            value={props.pendingFile.importedSkus}
                            clickHandler={() => props.toggleExpansion('success')}
                        />
                        <ImportResultIndicator
                            label={'Details zu bereits bekannten Produkten anzeigen.'}
                            icon={CheckCircleOutlined}
                            value={props.pendingFile.knownProducts}
                            clickHandler={() => props.toggleExpansion('already-known')}
                        />
                        <ImportResultIndicator
                            label={'Details zu Importfehlern anzeigen.'}
                            icon={Error}
                            value={props.pendingFile.errors.length}
                            clickHandler={() => props.toggleExpansion('errors')}
                        />
                        <ImportResultIndicator
                            label={'Details zu Produktupdates anzeigen.'}
                            icon={SwapHorizontalCircle}
                            value={props.pendingFile.unresolvedUpdates!!}
                            clickHandler={() => props.toggleExpansion('updates')}
                        />
                    </TableCell>
                    <TableCell>{uploadedDate}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{paddingBottom: 0, paddingTop: 0, backgroundColor: '#f5f5f5'}} colSpan={4}>
                        <Collapse in={isExpanded} unmountOnExit>
                            {props.expandedContent ? props.expandedContent : previousExpansionContent}
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );
    }
};

const ImportErrorOverview = (props: {importErrorFile: FailedImport}) => {
    const classes = useStyles();

    const uploadedDate = moment(props.importErrorFile.uploadedAt.toDate()).format('dd DD.MM.YYYY, HH:mm');
    return (
        <>
            <TableRow hover={true}>
                <TableCell>
                    <Error
                        aria-label={'Import Fehler'}
                        style={{verticalAlign: 'middle'}}
                        htmlColor={'red'}
                        titleAccess={'Import Fehler'}
                    />
                    <span style={{verticalAlign: 'middle'}} className={classes.marginLeft}>
                        {props.importErrorFile.name}
                    </span>
                </TableCell>
                <TableCell>{'-'}</TableCell>
                <TableCell role={'tablist'}>
                    <Chip
                        title={props.importErrorFile.importError}
                        role={'tab'}
                        aria-label={'Import Error'}
                        color={'primary'}
                        icon={<Error />}
                        label={'Fehler'}
                    />
                </TableCell>
                <TableCell>{uploadedDate}</TableCell>
            </TableRow>
        </>
    );
};

function CircularProgressWithLabel(props: any) {
    const variant = props.value === 100 ? 'static' : 'indeterminate';
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant={variant} {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center">
                <Typography variant="caption" component="div" color="textPrimary">{`${Math.round(
                    props.value
                )}%`}</Typography>
            </Box>
        </Box>
    );
}
