import Paper from '@material-ui/core/Paper';
import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {chain} from 'lodash';
import {UnconfirmedUpdate} from './model/UnconfirmedUpdate';

const useStyles = makeStyles(() => ({
    root: {width: '100%', height: '100%', padding: '10px'},
    heading: {fontWeight: 'bold', margin: '10px 0'},
    warning: {
        backgroundColor: '#ffe5e5',
        border: '1px solid #ff6464',
        padding: '3px',
        borderRadius: '3px',
        color: 'red',
        fontSize: '13px',
        marginBottom: '10px'
    },
    clickable: {cursor: 'pointer', '&:hover': {background: '#eee'}}
}));

export function NewUpdatesForFieldTile(props: {updates: UnconfirmedUpdate[]; field: string}) {
    const classes = useStyles();

    const updateCountByFieldName = chain(props.updates)
        .filter(it => it.keys.includes(props.field))
        .countBy(it => it.updates[`${props.field}`])
        .value();

    const updatesByNewValue = Object.keys(updateCountByFieldName).map(fieldName => {
        return (
            <div className={classes.clickable} key={fieldName} style={{display: 'flex'}}>
                <span style={{flex: 1}}>{fieldName}</span>
                <span>{updateCountByFieldName[fieldName]}</span>
            </div>
        );
    });

    if (updatesByNewValue.length === 0) {
        return null;
    }

    return (
        <Paper className={classes.root} aria-label={`updates-for-field`}>
            <div className={classes.heading}>Updates f&uuml;r {props.field}</div>
            {updatesByNewValue}
        </Paper>
    );
}
