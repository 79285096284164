import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import './App.css';
import {AppFrame} from './AppFrame/AppFrame';
import {EmailVerification} from './auth/EmailVerification';
import {LoginPage} from './auth/LoginPage';
import {ProtectedRoute} from './auth/ProtectedRoute';
import {routes} from './routes';
import {StartPage} from './StartPage/StartPage';
import RegistrationPage from './auth/RegistrationPage';

const App = () => {
    const env = process.env.REACT_APP_ENV;

    if (env !== 'prod' && env !== undefined) {
        document.title = `${env} caduceus`;
    }

    return (
        <div className={'root'}>
            <CssBaseline />
            <Router>
                <AppFrame>
                    <Switch>
                        <ProtectedRoute exact={true} path={routes.HOME} component={StartPage} />
                        <ProtectedRoute exact={true} path={routes.PRODUCTDATA_MAIN} component={StartPage} />
                        <Route exact={true} path={routes.VERIFICATION} component={EmailVerification} />
                        <Route exact={true} path={routes.LOGIN} component={LoginPage} />
                        <Route exact={true} path={routes.REGISTRATION} component={RegistrationPage} />
                        <Route exact={true} path={routes.UNAUTHORIZED} component={Unauthorized} />
                        <Route component={NotFound} />
                    </Switch>
                </AppFrame>
            </Router>
        </div>
    );
};

function NotFound() {
    return <h1>Die besuchte Seite existiert nicht.</h1>;
}

function Unauthorized() {
    return <h1>Du bist nicht berechtigt, die angefragte Seite zu besuchen.</h1>;
}

export default App;
