import React, {useMemo} from 'react';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/styles';
import {ImportSuccess, isImportSuccess} from '../../core/model/ImportSuccess';
import {container} from '../styles';
import {useQueryListener} from '../../core/hooks';
import {fiveMostRecentImports} from './queries';
import {ImportsOverviewTable} from './ImportsOverviewTable';
import {FailedImport, isFailedImport} from '../../core/model/FailedImport';

const useStyles = makeStyles(() => ({
    container: {
        ...container
    }
}));

export function ImportsOverviewContainer() {
    const classes = useStyles();

    const query = useMemo(() => {
        return fiveMostRecentImports();
    }, []);

    const snapshot = useQueryListener(query);

    const importResults: (ImportSuccess | FailedImport)[] = snapshot.querySnapshot
        ? snapshot.querySnapshot.docs.map(it => it.data()).filter(isImportResult)
        : [];

    return (
        <Paper className={classes.container}>
            <h4>Dropbox Import</h4>
            {importResults.length === 0 ? (
                <p>Es wurden noch keine Dateien importiert.</p>
            ) : (
                <ImportsOverviewTable importResults={importResults} />
            )}
        </Paper>
    );
}

// "any"-type is appropriate for typeguards
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isImportResult(object: any): object is ImportSuccess | FailedImport {
    return isImportSuccess(object) || isFailedImport(object);
}
