import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import {functionsClient} from '../../core/functionsClient';
import {productDataExportService} from './productDataExportService';
import {container} from '../styles';
import {ButtonWithProgress} from '../../components/ButtonWithProgress';

const useStyles = makeStyles(() => ({
    container: {
        ...container
    }
}));

export const ProductDataExport = () => {
    const classes = useStyles();

    const {isLoading, data: exportInfo} = productDataExportService.useExportInfo('product_data_export');
    const {isLoading: isLoading2, data: exportInfo2} = productDataExportService.useExportInfo(
        'product_data_export_ext'
    );

    if (isLoading || isLoading2) {
        return (
            <Paper className={classes.container}>
                <h4>Basisdaten</h4>
                <p>Daten werden geladen...</p>
            </Paper>
        );
    }

    if (exportInfo === null) {
        return (
            <Paper className={classes.container}>
                <h4>Basisdaten</h4>
                <p>Es stehen keine Daten zum Export bereit.</p>
                <RefreshButton />
            </Paper>
        );
    }

    const updatedAt = moment(exportInfo.updated.toDate()).format('DD.MM.YY HH:mm');
    return (
        <Paper className={classes.container}>
            <h4>Basisdaten</h4>
            <p>
                Enth&auml;lt {exportInfo.products} Produkte, zusammengestellt am {updatedAt}
            </p>
            {exportInfo2 && <p>Enth&auml;lt {exportInfo2.products} Produkte in der Erweiterung.</p>}
            <div>
                <div style={{width: '160px'}}>
                    <RefreshButton />
                </div>
                <div style={{width: '160px'}}>
                    <Button
                        data-testid={'download-button'}
                        variant={'outlined'}
                        href={exportInfo.downloadUrl}
                        fullWidth={true}>
                        Herunterladen
                    </Button>
                </div>
                {exportInfo2 && (
                    <div style={{width: '160px'}}>
                        <Button
                            data-testid={'download-button2'}
                            variant={'outlined'}
                            href={exportInfo2.downloadUrl}
                            fullWidth={true}>
                            Erweiterung Herunterladen
                        </Button>
                    </div>
                )}
            </div>
        </Paper>
    );
};

const RefreshButton = () => {
    return (
        <ButtonWithProgress
            data-testid={'prepare-export'}
            variant={'contained'}
            color={'primary'}
            action={functionsClient.prepareExport}>
            Aktualisieren
        </ButtonWithProgress>
    );
};
