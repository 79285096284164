import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/styles';
import React from 'react';
import {useAuth} from '../auth/AuthProvider';
import {UserControl} from './UserControl/UserControl';

const useStyles = makeStyles(() => ({
    grow: {
        flexGrow: 1
    },
    topBar: {
        height: 32,
        backgroundColor: '#EEE'
    },
    environmentTag: {
        color: '#fdc508',
        fontSize: '1rem'
    }
}));

export const TopBar = () => {
    const classes = useStyles();
    const auth = useAuth();

    const env = process.env.REACT_APP_ENV;
    const envTag = (
        <span data-testid={'env-tag'} className={classes.environmentTag}>
            {env}
        </span>
    );

    return (
        <AppBar position="fixed">
            <Toolbar className={classes.topBar}>
                <Typography variant="h6" color="inherit" className={classes.grow}>
                    Caduceus{env !== 'prod' ? envTag : null}
                </Typography>
                {auth.firebaseAuth && (
                    <span data-testid={'userControl'}>
                        <UserControl />
                    </span>
                )}
            </Toolbar>
        </AppBar>
    );
};
