import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import {functionsClient} from '../core/functionsClient';
import {container} from './styles';
import {productDataExportService} from './ProductDataExport/productDataExportService';

const useStyles = makeStyles(() => ({
    container: {
        ...container
    }
}));

export const UnmatchedSkuExport = () => {
    const classes = useStyles();

    const {isLoading, data: exportInfo} = productDataExportService.useExportInfo('unknown_product_data_export');

    if (isLoading) {
        return (
            <Paper className={classes.container}>
                <h4>Unzuweisbare SKUs</h4>
                <p>Daten werden geladen...</p>
            </Paper>
        );
    }

    if (exportInfo === null) {
        return (
            <Paper className={classes.container}>
                <h4>Unzuweisbare SKUs</h4>
                <p>Es stehen keine Daten zum Export bereit.</p>
                <RefreshButton />
            </Paper>
        );
    }

    const updatedAt = moment(exportInfo.updated.toDate()).format('DD.MM.YY HH:mm');
    return (
        <Paper className={classes.container}>
            <h4>Unzuweisbare SKUs</h4>
            <p>
                Enth&auml;lt {exportInfo.products} SKUs, zusammengestellt am {updatedAt}
            </p>
            <RefreshButton />
            <Button data-testid={'download-button'} variant={'outlined'} href={exportInfo.downloadUrl}>
                Herunterladen
            </Button>
        </Paper>
    );
};

const RefreshButton = () => {
    return (
        <Button
            data-testid={'prepare-export'}
            variant={'contained'}
            color={'primary'}
            onClick={functionsClient.prepareUnmatchedAmzExport}>
            Aktualisieren
        </Button>
    );
};
