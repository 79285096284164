import axios from 'axios';
import * as firebase from 'firebase/app';
import {getEnv} from './helpers';

export const frontendClient = {
    async deleteAllUpdates(importId: string, auth: firebase.User) {
        try {
            const baseUrl = getEnv('REACT_APP_FRONTEND_URL');
            const token = await auth.getIdToken();
            await axios.get(`${baseUrl}/product-import/${importId}/updates/delete`, {
                headers: {Authorization: 'Bearer ' + token}
            });
        } catch (e) {
            console.error(e);
        }
    },

    async deleteUpdatesForField(params: {importId: string; fieldName: string; auth: firebase.User}) {
        const {importId, fieldName, auth} = params;
        try {
            const baseUrl = getEnv('REACT_APP_FRONTEND_URL');
            const token = await auth.getIdToken();
            await axios.get(`${baseUrl}/product-import/${importId}/updates/delete?fieldName=${fieldName}`, {
                headers: {Authorization: 'Bearer ' + token}
            });
        } catch (e) {
            console.error(e);
        }
    },

    async applyUpdatesForField(params: {importId: string; fieldName: string; auth: firebase.User}) {
        const {importId, fieldName, auth} = params;
        try {
            const baseUrl = getEnv('REACT_APP_FRONTEND_URL');
            const token = await auth.getIdToken();
            await axios.get(`${baseUrl}/product-import/${importId}/updates/apply?fieldName=${fieldName}`, {
                headers: {Authorization: 'Bearer ' + token}
            });
        } catch (e) {
            console.error(e);
        }
    },

    async applyUpdatesForType(params: {
        importId: string;
        auth: firebase.User;
        type: 'product' | 'article' | 'variation';
    }) {
        const {importId, auth, type} = params;
        try {
            const baseUrl = getEnv('REACT_APP_FRONTEND_URL');
            const token = await auth.getIdToken();
            const url = `${baseUrl}/product-import/v2/${importId}/updates/apply?type=${type}`;
            await axios.get(url, {
                headers: {Authorization: 'Bearer ' + token}
            });
        } catch (e) {
            console.error(e);
        }
    },

    async deleteAllUpdatesV2(params: {importId: string; auth: firebase.User}) {
        const {importId, auth} = params;
        try {
            const baseUrl = getEnv('REACT_APP_FRONTEND_URL');
            const token = await auth.getIdToken();
            const url = `${baseUrl}/product-import/v2/${importId}/updates/delete`;
            await axios.get(url, {
                headers: {Authorization: 'Bearer ' + token}
            });
        } catch (e) {
            console.error(e);
        }
    }
};
