import React, {useEffect, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import {makeStyles} from '@material-ui/styles';
import {container} from '../styles';
import {fbCaduceus} from '../../core/firebase';
import {functionsClient} from '../../core/functionsClient';
import {ButtonWithProgress} from '../../components/ButtonWithProgress';
import {storageClient} from '../../core/storageClient';
import Button from '@material-ui/core/Button';
import moment from 'moment';

const useStyles = makeStyles(() => ({
    container: {
        ...container
    }
}));

type State = {isLoading: true} | {isLoading: false; downloadUrl: string; updatedAt: string};

function ProductDataExportFromBackend() {
    const classes = useStyles();
    const [data, setData] = useState<State>({isLoading: true});

    useEffect(() => {
        fbCaduceus
            .firestore()
            .collection('product_export_files')
            .orderBy('updated', 'desc')
            .limit(1)
            .onSnapshot(async it => {
                const data = it.docs[0].data();
                const downloadUrl = await storageClient.getDownloadURL(data.path);
                const updatedAt = moment(data.updated.toDate()).format('DD.MM.YY HH:mm');

                setData({isLoading: false, downloadUrl: downloadUrl, updatedAt});
            });
    }, []);

    return (
        <Paper className={classes.container}>
            <h4>Basisdaten</h4>
            {data.isLoading ? null : (
                <>
                    <p>Zusammengestellt am {data.updatedAt}</p>
                    <div style={{width: '160px'}}>
                        <Button
                            data-testid={'download-button'}
                            variant={'outlined'}
                            href={data.downloadUrl}
                            fullWidth={true}>
                            Herunterladen
                        </Button>
                    </div>
                </>
            )}
            <div style={{width: '160px'}}>
                <ButtonWithProgress
                    data-testid={'prepare-export'}
                    variant={'contained'}
                    color={'primary'}
                    action={functionsClient.prepareExport}>
                    Aktualisieren
                </ButtonWithProgress>
            </div>
        </Paper>
    );
}

export default ProductDataExportFromBackend;
