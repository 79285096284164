import {fbCaduceus} from './firebase';

export const storageClient = {
    getDownloadURL(path: string): Promise<string> {
        return fbCaduceus
            .storage()
            .ref(path)
            .getDownloadURL();
    }
};
