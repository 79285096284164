import React from 'react';
import {Redirect} from 'react-router-dom';
import {routes} from '../routes';
import {useAuth} from './AuthProvider';

export const EmailVerification = () => {
    const auth = useAuth();

    if (auth.firebaseAuth === null) {
        return <Redirect to={routes.LOGIN} />;
    }

    if (auth.firebaseAuth.emailVerified) {
        return <Redirect to={routes.HOME} />;
    }

    const handler = () => {
        return auth.firebaseAuth!!.sendEmailVerification({
            url: process.env.REACT_APP_DOMAIN!!
        });
    };

    return (
        <>
            <h1>Emailaddresse bestätigen</h1>
            <p>
                Die angegebene Emailaddresse &quot;{auth.firebaseAuth.email}&quot; wurde noch nicht bestätigt. Bitte
                überprüfe dein Postfach, ob Du eine Email von &quot;noreply@caduceus.firebaseapp.com&quot; erhalten
                hast, und bestätige die Registrierung, indem Du auf den Aktivierungslink klickst.
            </p>
            <button data-qa="send-verify" onClick={handler}>
                Email erneut senden
            </button>
        </>
    );
};
